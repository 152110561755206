const searchbar = document.querySelector('.menu_top_searchbar')

const opensearchbarModalIcon = document.querySelector('.menu_top_searchbar_mobile_icon')
const closeSearchbarModalIcon = document.querySelector('.close_searchbar_mobile_modal')

function toggleSearchbar() {
  searchbar.classList.toggle('active')
  document.querySelector('.ui-autocomplete-input').focus()
}

opensearchbarModalIcon && opensearchbarModalIcon.addEventListener('click', () => toggleSearchbar())
closeSearchbarModalIcon && closeSearchbarModalIcon.addEventListener('click', () => toggleSearchbar())

// -------------------------------------------------------------------------
// --------------------------------------------------------- 🍔 Hamburger 🍔
// -------------------------------------------------------------------------
const menuBottomContainer = document.querySelector('#menu_bottom_container')
if (menuBottomContainer) {
  const ham = document.querySelector('.ham')
  ham.addEventListener('click', () => {
    ham.classList.toggle('active')
    menuBottomContainer.classList.toggle('active')
  })
}

// -------------------------------------------------------------------------
// -------------------------------------------------- 🇬🇧 Langage dropdown 🇬🇧
// -------------------------------------------------------------------------
const currentLangage = document.querySelector('img.current_lang')
if (currentLangage) {
  const langagesDropdown = document.querySelector('.langages_dropdown')
  currentLangage.addEventListener('click', () => langagesDropdown.classList.toggle('active'))
}

// -------------------------------------------------------------------------
// -------------------------------------- 🧑‍💼 Account creation form fields 🧑‍💼
// -------------------------------------------------------------------------
const registerForm = document.querySelector('.register-form')
const addressForm = document.querySelector('.js-address-form')

// const b2bFieldsRequired = document.querySelector('#b2b_fields_required').dataset.b2b

if (registerForm) {
  const fieldsToMakeRequired = [
    registerForm.querySelector('input#field-company') ?? null,
    // registerForm.querySelector('input#field-siret') ?? null,
  ]

  fieldsToMakeRequired.forEach((input) => {
    if (input) {
      // Make required
      input.required = true
      // Remove txt "optional"
      const grandParent = input.parentNode.parentNode
      const comment = grandParent.querySelector('.form-control-comment')
      if (comment) comment.style.display = 'none'
    }
  })
}

if (addressForm) {
  const fieldsToMakeRequired = [
    addressForm.querySelector('input#field-company') ?? null,
    // addressForm.querySelector('input#field-vat_number') ?? null
  ]

  fieldsToMakeRequired.forEach((input) => {
    if (input) {
      // Make required
      input.required = true
      // Remove txt "optional"
      const grandParent = input.parentNode.parentNode
      const comment = grandParent.querySelector('.form-control-comment')
      if (comment) comment.style.display = 'none'
    }
  })
}
